import React from 'react';
import * as days from 'dayjs';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

export const DatosAlumno = ({ datos, folio }) => {
  const arrTitulos = [];

  const agrupaMaterias = (obj, prop) =>
    obj.reduce((acc, item) => {
      const key = item[prop];
      const { Nombre_Materia, Calificacion } = item;

      if (!acc[key]) {
        acc[key] = [];
      }
      arrTitulos.push(key);
      acc[key].push({
        Nombre_Materia,
        Calificacion
      });
      return acc;
    }, {});

  let materias;

  if (!datos.materias) {
    materias = datos.materias;
  } else {
    materias = agrupaMaterias(datos.materias, 'Seccion');
  }

  const titulosSeccion = arrTitulos.filter(
    (item, index) => arrTitulos.indexOf(item) === index
  );

  return (
    <>
      <Typography variant="h2">
        <TableContainer>
          <Table sx={{ marginTop: '20px', fontSize: '18px' }}>
            <TableHead sx={{ backgroundColor: '#cccccc' }}>
              <TableRow>
                <TableCell sx={{ fontSize: '18px', textAlign: 'center' }}>
                  Matrícula
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ fontSize: '18px', textAlign: 'center' }}
                >{`${datos.documento.Matricula}`}</TableCell>
              </TableRow>
            </TableBody>
            <TableHead sx={{ backgroundColor: '#cccccc' }}>
              <TableRow>
                <TableCell sx={{ fontSize: '18px', textAlign: 'center' }}>
                  Nombre
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ fontSize: '18px', textAlign: 'center' }}
                >{`${datos.documento.Nombre}`}</TableCell>
              </TableRow>
            </TableBody>
            <TableHead sx={{ backgroundColor: '#cccccc' }}>
              <TableRow>
                <TableCell sx={{ fontSize: '18px', textAlign: 'center' }}>
                  Plan de estudio
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ fontSize: '18px', textAlign: 'center' }}
                >{`${datos.documento.Descripcion_Plan}`}</TableCell>
              </TableRow>
            </TableBody>
            <TableHead sx={{ backgroundColor: '#cccccc' }}>
              <TableRow>
                <TableCell sx={{ fontSize: '18px', textAlign: 'center' }}>
                  Promedio
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ fontSize: '18px', textAlign: 'center' }}
                >{`${datos.documento?.Promedio}`}</TableCell>
              </TableRow>
            </TableBody>
            <TableHead sx={{ backgroundColor: '#cccccc' }}>
              <TableRow>
                <TableCell sx={{ fontSize: '18px', textAlign: 'center' }}>
                  Fecha expedición
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ fontSize: '18px', textAlign: 'center' }}
                >{`${days(datos.documento.Fecha_Creacion).format(
                  'DD/MM/YYYY'
                )}`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {datos.documento.Texto === 'false' ? (
            ''
          ) : (
            <Table sx={{ marginTop: '20px', fontSize: '18px' }}>
              <TableHead sx={{ backgroundColor: '#cccccc' }}>
                <TableRow>
                  <TableCell sx={{ fontSize: '16px', textAlign: 'center' }}>
                    Periodo
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{ fontSize: '16px' }}
                  >{`${datos.documento.Texto}`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Typography>
      {!datos.resumen ? (
        ''
      ) : (
        <Typography variant="h2">
          <TableContainer>
            {datos.resumen.map((res) => (
              <Table sx={{ marginTop: '20px', fontSize: '18px' }}>
                <TableHead sx={{ backgroundColor: '#cccccc' }}>
                  <TableRow>
                    <TableCell sx={{ fontSize: '18px', textAlign: 'center' }}>
                      {res.Descripcion}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{ fontSize: '18px', textAlign: 'center' }}
                    >{`${res.Valor}`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ))}
          </TableContainer>
        </Typography>
      )}
      {!datos.materias ? (
        ''
      ) : (
        <Typography variant="h6">
          <TableContainer>
            {titulosSeccion.map((titulos) => (
              <Table sx={{ marginTop: '20px' }}>
                <TableHead sx={{ backgroundColor: '#cccccc' }}>
                  <TableRow key={titulos}>
                    <TableCell
                      sx={{
                        width: '70%',
                        textAlign: 'center',
                        fontSize: '16px'
                      }}
                    >
                      {titulos.trim()}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '30%',
                        textAlign: 'center',
                        fontSize: '16px'
                      }}
                    >
                      Calificacion/Grade
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {materias[titulos].map((materia) => (
                    <TableRow>
                      <TableCell
                        sx={{
                          width: '70%',
                          textAlign: 'center',
                          fontSize: '16px'
                        }}
                      >
                        {materia.Nombre_Materia.trim()}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '30%',
                          textAlign: 'center',
                          fontSize: '16px'
                        }}
                      >
                        {materia.Calificacion}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ))}
          </TableContainer>
        </Typography>
      )}
    </>
  );
};
