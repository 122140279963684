import React, { useEffect, useState } from 'react';
import * as dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

import { Button, Stack, TextField, Typography } from '@mui/material';
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha
} from 'react-simple-captcha';
import { fetchValidaDoc } from '../helpers/fetchValidaDoc';

export const Captcha = ({ folio, onSetIsCaptcha, onDatos }) => {
  const initialState = {
    codigo: '',
    captcha: ''
  };

  dayjs.extend(LocalizedFormat);
  dayjs.extend(utc);
  const [values, setValues] = useState(initialState);
  const [textValidacion, setTextValidacion] = useState('');

  const { codigo, captcha } = values;

  useEffect(() => {
    loadCaptchaEnginge(6, '#FFD600', 'white');
  }, []);

  const handleInputChange = (e) => {
    setValues({
      ...values,
      id: folio,
      [e.target.name]: e.target.value
    });
  };

  const getDatosValida = async (criterios) => {
    // const resp = await fetchValidaDoc(`documentoValida`, criterios, 'POST');
    const resp = await fetchValidaDoc(`ValidadorForms`, criterios, 'POST');
    const body = await resp.json();
    return body;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const resultado = await getDatosValida(values);
    console.log(resultado);
    const dateExpiracion = dayjs().isBefore(dayjs.utc(resultado.documento.Fecha_Expiracion ));

    console.log('Fecha Expiracion', dateExpiracion);

    if (
      validateCaptcha(values.captcha) &&
      resultado.ok === true &&
      dateExpiracion
    ) {
      onDatos(resultado);
      onSetIsCaptcha(true);
    } else {
      loadCaptchaEnginge(6, '#FFD600', 'white');
      if (!dateExpiracion) {
        setTextValidacion('*Documento Expirado');
      } else {
        setTextValidacion('*Código validador y/o Captcha, incorrectos');
      }
      onSetIsCaptcha(false);
      setValues(initialState);
    }
  };
  
  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Stack direction="column" spacing={2} sx={{ marginBottom: '40px' }}>
          <TextField
            id="codigoN"
            label="Código validador"
            variant="outlined"
            fullWidth
            required
            name="codigo"
            value={codigo}
            onChange={handleInputChange}
          />
        </Stack>
        <Stack direction="column" spacing={2} sx={{ marginBottom: '20px' }}>
          <Typography variant="h6" sx={{}}>
            <LoadCanvasTemplate
              reloadText="Generar Captcha"
              reloadColor="#000"
            />
          </Typography>
          <TextField
            id="captchaN"
            label="Captcha"
            variant="outlined"
            required
            fullWidth
            name="captcha"
            value={captcha}
            onChange={handleInputChange}
          />
        </Stack>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          label="Submit"
        >
          Validar
        </Button>
        <Typography variant="h6" color="red">
          {textValidacion}
        </Typography>
      </form>
    </>
  );
};
