const validadorUrl = process.env.REACT_APP_API_DOC_URL;

const fetchValidaDoc = (endpoint, data, method = 'POST') => {
  const url = `${validadorUrl}/${endpoint}`;

  return fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
};

// const fetchValidaDoc = (endpoint) => {
//   const url = `${validadorUrl}/${endpoint}`;
//   return fetch(url);
// };

export { fetchValidaDoc };
