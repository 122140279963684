import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, CssBaseline, Link, Paper, Grid, Typography } from '@mui/material';
// import { fetchValidaDoc } from '../helpers/fetchValidaDoc';
import { DatosAlumno } from '../components/DatosAlumno';
import { Captcha } from '../components/Captcha';

const Copyright = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {' © Instituto Educativo del Noroeste A.C. '}
      <br />
      <Link color="inherit" href="https://www.cetys.mx/">
        www.cetys.mx
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
};

export const Valida = () => {
  const [datos, setDatos] = useState({});
  const [isCaptcha, setIsCaptcha] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const folio = queryParams.get('folio');

  return (
    <>
      <Helmet>
        <title> CETYS Universidad</title>
      </Helmet>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        {/* <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage: 'url(../images/chica.jpg)',
            backgroundRepeat: 'repeat-y',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        /> */}
        <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box
              component="img"
              src="../images/CETYS_Logo02_20.jpg"
              sx={{ width: 250, marginBottom: 8 }}
            />
            {!isCaptcha ? (
              <Captcha
                folio={folio}
                onSetIsCaptcha={setIsCaptcha}
                onDatos={setDatos}
              />
            ) : (
              <DatosAlumno datos={datos} folio={folio} />
            )}
            <Box sx={{ mt: 1 }}>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
