import { ThemeProvider } from '@mui/system';
import { Valida } from './pages/Valida';
import { theme } from './theme';
// import { AppRouter } from './AppRouter';
// <AppRouter />;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Valida />
    </ThemeProvider>
  );
}

export default App;
